import revive_payload_client_MKLSl1Q3Xo from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VQfN7BoqBq from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0cokeqc7pg from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_OJLuKRLkhr from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt-site-config@2.2.16_rollup@4.21.2_vite@5.4.3_vue@3.5.3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_lY5QvDzi05 from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_emIlCBmP2l from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rl4O6dhcea from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fWLhXY1tdj from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_eFtz3Xb8qi from "/usr/src/nuxt-app/node_modules/.pnpm/@pinia+nuxt@0.4.7_rollup@4.21.2_typescript@5.0.2_vue@3.5.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_JpFDTZvVhE from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_xiRPgat6b1 from "/usr/src/nuxt-app/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_qwz7g5d6ovyc2nj6ltbsmj3myu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/nuxt-app/.nuxt/sentry-client-config.mjs";
import init_grdyAU8lhf from "/usr/src/nuxt-app/plugins/init.ts";
import install_device_tags_script_client_ytPCaoqvVJ from "/usr/src/nuxt-app/plugins/install-device-tags-script.client.ts";
import snowplowTracking_client_5JoiVSpIcm from "/usr/src/nuxt-app/plugins/snowplowTracking.client.ts";
import vue_social_sharing_ZALq4hYFAx from "/usr/src/nuxt-app/plugins/vue-social-sharing.ts";
export default [
  revive_payload_client_MKLSl1Q3Xo,
  unhead_VQfN7BoqBq,
  router_0cokeqc7pg,
  _0_siteConfig_OJLuKRLkhr,
  payload_client_lY5QvDzi05,
  navigation_repaint_client_emIlCBmP2l,
  check_outdated_build_client_rl4O6dhcea,
  chunk_reload_client_fWLhXY1tdj,
  plugin_vue3_eFtz3Xb8qi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JpFDTZvVhE,
  sentry_client_xiRPgat6b1,
  sentry_client_config_o34nk2sJbg,
  init_grdyAU8lhf,
  install_device_tags_script_client_ytPCaoqvVJ,
  snowplowTracking_client_5JoiVSpIcm,
  vue_social_sharing_ZALq4hYFAx
]