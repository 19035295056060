import { default as _91_46_46_46slug_939PibsNVsnhMeta } from "/usr/src/nuxt-app/pages/[...slug].vue?macro=true";
import { default as _91player_938L9pAMdYuoMeta } from "/usr/src/nuxt-app/pages/clubs/[club]/[player].vue?macro=true";
import { default as fixtures_45resultsxzFVX5GHe4Meta } from "/usr/src/nuxt-app/pages/clubs/[club]/index/fixtures-results.vue?macro=true";
import { default as indexfV39cq1M5zMeta } from "/usr/src/nuxt-app/pages/clubs/[club]/index/index.vue?macro=true";
import { default as newsxGOuxSdvwmMeta } from "/usr/src/nuxt-app/pages/clubs/[club]/index/news.vue?macro=true";
import { default as squadTjzmUfCaggMeta } from "/usr/src/nuxt-app/pages/clubs/[club]/index/squad.vue?macro=true";
import { default as standingsnQab4kzTUBMeta } from "/usr/src/nuxt-app/pages/clubs/[club]/index/standings.vue?macro=true";
import { default as statsBhsM3kmuIcMeta } from "/usr/src/nuxt-app/pages/clubs/[club]/index/stats.vue?macro=true";
import { default as indexvBxnQfA4V3Meta } from "/usr/src/nuxt-app/pages/clubs/[club]/index.vue?macro=true";
import { default as index9vYwl3grXpMeta } from "/usr/src/nuxt-app/pages/clubs/index.vue?macro=true";
import { default as fixtures_45resultsV3qAG9dBCAMeta } from "/usr/src/nuxt-app/pages/competitions/[competition]/fixtures-results.vue?macro=true";
import { default as formatuozMzcNCmZMeta } from "/usr/src/nuxt-app/pages/competitions/[competition]/format.vue?macro=true";
import { default as indexs2bU1MKj1tMeta } from "/usr/src/nuxt-app/pages/competitions/[competition]/index.vue?macro=true";
import { default as latestKLgqU76rLlMeta } from "/usr/src/nuxt-app/pages/competitions/[competition]/latest.vue?macro=true";
import { default as standingsn1OPuHh27nMeta } from "/usr/src/nuxt-app/pages/competitions/[competition]/standings.vue?macro=true";
import { default as competitionsHlV8hn91S8Meta } from "/usr/src/nuxt-app/pages/competitions.vue?macro=true";
import { default as contact_45us86bi4zmYgUMeta } from "/usr/src/nuxt-app/pages/contact-us.vue?macro=true";
import { default as fixtures_45results0RzRS56FCMMeta } from "/usr/src/nuxt-app/pages/fixtures-results.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as lineups1cLPcNLG16Meta } from "/usr/src/nuxt-app/pages/match-centre/[match-Id]/lineups.vue?macro=true";
import { default as live_45commentaryRnTh1lCLu3Meta } from "/usr/src/nuxt-app/pages/match-centre/[match-Id]/live-commentary.vue?macro=true";
import { default as newslJazNhBuPbMeta } from "/usr/src/nuxt-app/pages/match-centre/[match-Id]/news.vue?macro=true";
import { default as season_45statsgSV5EMPUHrMeta } from "/usr/src/nuxt-app/pages/match-centre/[match-Id]/season-stats.vue?macro=true";
import { default as standingsk4nEkylMSjMeta } from "/usr/src/nuxt-app/pages/match-centre/[match-Id]/standings.vue?macro=true";
import { default as team_45statsuTDE7yOY49Meta } from "/usr/src/nuxt-app/pages/match-centre/[match-Id]/team-stats.vue?macro=true";
import { default as match_45centrevUIIoukTPzMeta } from "/usr/src/nuxt-app/pages/match-centre.vue?macro=true";
import { default as _91slug_93wkBDbFQkHIMeta } from "/usr/src/nuxt-app/pages/news/[slug].vue?macro=true";
import { default as indexAqnIhg14ssMeta } from "/usr/src/nuxt-app/pages/news/index.vue?macro=true";
import { default as newsmVdea58f4ZMeta } from "/usr/src/nuxt-app/pages/news.vue?macro=true";
import { default as searchiOAa32tjZNMeta } from "/usr/src/nuxt-app/pages/search.vue?macro=true";
import { default as index3seNkJaVIUMeta } from "/usr/src/nuxt-app/pages/season-rankings/index.vue?macro=true";
import { default as player_45rankingssVEPBhfq5LMeta } from "/usr/src/nuxt-app/pages/season-rankings/player-rankings.vue?macro=true";
import { default as team_45rankingsObGOEnwqNGMeta } from "/usr/src/nuxt-app/pages/season-rankings/team-rankings.vue?macro=true";
import { default as standingsXuRffmfwatMeta } from "/usr/src/nuxt-app/pages/standings.vue?macro=true";
import { default as terms_45of_45use0yT59WLrN1Meta } from "/usr/src/nuxt-app/pages/terms-of-use.vue?macro=true";
import { default as component_45stubLKTUHiwLtuMeta } from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLKTUHiwLtu } from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.21.2_typescript@5.0.2_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/usr/src/nuxt-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "clubs-club-player",
    path: "/clubs/:club()/:player()",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/[player].vue").then(m => m.default || m)
  },
  {
    name: indexvBxnQfA4V3Meta?.name,
    path: "/clubs/:club()",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "clubs-club-index-fixtures-results",
    path: "fixtures-results",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index/fixtures-results.vue").then(m => m.default || m)
  },
  {
    name: "clubs-club-index",
    path: "",
    meta: indexfV39cq1M5zMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "clubs-club-index-news",
    path: "news",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index/news.vue").then(m => m.default || m)
  },
  {
    name: "clubs-club-index-squad",
    path: "squad",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index/squad.vue").then(m => m.default || m)
  },
  {
    name: "clubs-club-index-standings",
    path: "standings",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index/standings.vue").then(m => m.default || m)
  },
  {
    name: "clubs-club-index-stats",
    path: "stats",
    component: () => import("/usr/src/nuxt-app/pages/clubs/[club]/index/stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clubs",
    path: "/clubs",
    component: () => import("/usr/src/nuxt-app/pages/clubs/index.vue").then(m => m.default || m)
  },
  {
    name: "competitions",
    path: "/competitions",
    component: () => import("/usr/src/nuxt-app/pages/competitions.vue").then(m => m.default || m),
    children: [
  {
    name: "competitions-competition-fixtures-results",
    path: ":competition()/fixtures-results",
    component: () => import("/usr/src/nuxt-app/pages/competitions/[competition]/fixtures-results.vue").then(m => m.default || m)
  },
  {
    name: "competitions-competition-format",
    path: ":competition()/format",
    component: () => import("/usr/src/nuxt-app/pages/competitions/[competition]/format.vue").then(m => m.default || m)
  },
  {
    name: "competitions-competition",
    path: ":competition()",
    component: () => import("/usr/src/nuxt-app/pages/competitions/[competition]/index.vue").then(m => m.default || m)
  },
  {
    name: "competitions-competition-latest",
    path: ":competition()/latest",
    component: () => import("/usr/src/nuxt-app/pages/competitions/[competition]/latest.vue").then(m => m.default || m)
  },
  {
    name: "competitions-competition-standings",
    path: ":competition()/standings",
    component: () => import("/usr/src/nuxt-app/pages/competitions/[competition]/standings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/usr/src/nuxt-app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "fixtures-results",
    path: "/fixtures-results",
    component: () => import("/usr/src/nuxt-app/pages/fixtures-results.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "match-centre",
    path: "/match-centre",
    component: () => import("/usr/src/nuxt-app/pages/match-centre.vue").then(m => m.default || m),
    children: [
  {
    name: "match-centre-matchId-lineups",
    path: ":matchId()/lineups",
    component: () => import("/usr/src/nuxt-app/pages/match-centre/[match-Id]/lineups.vue").then(m => m.default || m)
  },
  {
    name: "match-centre-matchId-live-commentary",
    path: ":matchId()/live-commentary",
    component: () => import("/usr/src/nuxt-app/pages/match-centre/[match-Id]/live-commentary.vue").then(m => m.default || m)
  },
  {
    name: "match-centre-matchId-news",
    path: ":matchId()/news",
    component: () => import("/usr/src/nuxt-app/pages/match-centre/[match-Id]/news.vue").then(m => m.default || m)
  },
  {
    name: "match-centre-matchId-season-stats",
    path: ":matchId()/season-stats",
    component: () => import("/usr/src/nuxt-app/pages/match-centre/[match-Id]/season-stats.vue").then(m => m.default || m)
  },
  {
    name: "match-centre-matchId-standings",
    path: ":matchId()/standings",
    component: () => import("/usr/src/nuxt-app/pages/match-centre/[match-Id]/standings.vue").then(m => m.default || m)
  },
  {
    name: "match-centre-matchId-team-stats",
    path: ":matchId()/team-stats",
    component: () => import("/usr/src/nuxt-app/pages/match-centre/[match-Id]/team-stats.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsmVdea58f4ZMeta?.name,
    path: "/news",
    component: () => import("/usr/src/nuxt-app/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: "news-slug",
    path: ":slug()",
    component: () => import("/usr/src/nuxt-app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "",
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/usr/src/nuxt-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "season-rankings",
    path: "/season-rankings",
    component: () => import("/usr/src/nuxt-app/pages/season-rankings/index.vue").then(m => m.default || m)
  },
  {
    name: "season-rankings-player-rankings",
    path: "/season-rankings/player-rankings",
    component: () => import("/usr/src/nuxt-app/pages/season-rankings/player-rankings.vue").then(m => m.default || m)
  },
  {
    name: "season-rankings-team-rankings",
    path: "/season-rankings/team-rankings",
    component: () => import("/usr/src/nuxt-app/pages/season-rankings/team-rankings.vue").then(m => m.default || m)
  },
  {
    name: "standings",
    path: "/standings",
    component: () => import("/usr/src/nuxt-app/pages/standings.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/usr/src/nuxt-app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/news/tickets",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/beginners-guide",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/bath/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/bath-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/btah-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/bath-rugby",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/bistol-bears/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/bistol-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/bistol-bears",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/bristol-bears/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/bristol-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/bristol-bears",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/exeter/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/exeter-cheifs/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/exeter-chiefs/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/exeter-chiefs",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/gloucester/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/gloucester-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/gloucester-rugby",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/harlequin/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/harlequins/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/harlequis/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/harlequins",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/clubs/harlequins",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/leicester/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/leicester-tigers/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/leicester-tigrs/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/leiceter-tigrs/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/leiceter-tigers/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/tigers/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/leicester-tigers",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/falcons/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/newcastle/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/newcastle-falcons/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/newcastle-falcons",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/northamption-saints/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/northampton-saints/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/northampton/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/saints/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/northampton-saints",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/sale/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/sale-sharks/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/sharks/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/sale-sharks",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/saracens/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/sarries/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/club/saracens",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community-2",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community-player-of-the-month",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community-weekend-2023-spotlight-on-11-incredible-stories",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/charity-partnerships/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/community-brochure",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/community-contacts",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/community-news",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/learnwithus",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/movelikeapro",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/tacklinghealth",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/communityweekend2021",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/in-the-community/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/community/defender-premiership-cup",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/project-rugby",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/projectrugby",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/contact",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/cookiepolicy",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/faqs",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/find-a-pub-for-a-chance-to-win-tickets-to-the-gallagher-premiership-rugby-final-2023",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/gilbertgallagherpremiershiprugbyfinal2022",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/who-we-work-with/epcr",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/who-we-work-with/the-rfu",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/who-we-work-with/the-rpa",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/who-we-work-with/world-rugby",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/about-premiership-rugby/about-us/history",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/all-clubs/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/news/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/latest-news",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/semi-final/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/semi-finals/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/international-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/match-preview/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/match-previews/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/highlights/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/match-highlights/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/match-report/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/match-reports/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/match-report",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/transfer-news/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/try-of-the-week/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/citizen-try-of-the-week-winners-2015-2016",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/citizen-try-of-the-week-winners-2016-2017",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/citizen-try-of-the-week-winners-2017-2018",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/citizen-try-of-the-week-winners-2018-2019",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/try-of-the-week",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/category/womens-rugby/:pathMatch(.*)",
    component: component_45stubLKTUHiwLtu
  },
  {
    name: component_45stubLKTUHiwLtuMeta?.name,
    path: "/womens-rugby",
    component: component_45stubLKTUHiwLtu
  }
]